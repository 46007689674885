import { combineReducers } from "redux";

import auth from "./auth.js";
import dash from "./dash.js";
import transactions from './transactions.js';
import vehiculo from './vehiculo';
import events from './events'
import user from './user'
import tickets from './tickets';
import multipleEvents from './multipleEvents'
import shuttle from './shuttle'
import health from './health'


const reducers = combineReducers({
	auth,
	dash,
	transactions,
	vehiculo,
	events,
	user,
	tickets,
	multipleEvents,
	shuttle,
	health
});

export default reducers;