import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import *as navigation from '../../_nav';
// routes config
import * as routesPage from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import * as actions from '../../actions/dash';

class DefaultLayout extends Component {

  

  render() {

    let {user} = this.props.auth;
    let navigation_user = [];

    let routes = [];

    if(user.rol == 1){
      navigation_user = navigation.admin_nav;
      routes = routesPage.routes;
    }
    else if(user.rol == 3){
      navigation_user = navigation.check_in;
      routes = routesPage.routesCheckIn;
    }
    else if (user.rol == 4){
      navigation_user = navigation.admin_check;
      routes = routesPage.routesAdminCheck;
    }else if (user.rol == 5){
      navigation_user = navigation.check_in_temp;
      routes = routesPage.routesCheckInTemp;
    }
    else if (user.rol == 6){
      navigation_user = navigation.customer_service;
      routes = routesPage.routesCustomer;
    }
    else if(user.rol == 7){
      navigation_user = navigation.check_shuttle;
      routes = routesPage.routesShuttles;
    }
    else if(user.rol == 8){
      navigation_user = navigation.onsitetest;
      routes = routesPage.routesOnSite;
    }
    
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader user={user.name}/>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation_user} {...this.props} />
            <AppSidebarFooter />  
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes}/>
            <Container fluid style={{padding : '0px'}}>
               <Switch>
                 {routes.map((route, idx) => {
                     return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                         <route.component {...props} />
                       )} />)
                       : (null);
                   },
                 )}
                {
                  user.rol == 3 &&
                    <Redirect from="/" to="app/checkin" />
                }
                {
                  user.rol == 1 &&
                    <Redirect from="/" to="app/admindash" />
                }
                {
                  user.rol == 4 &&
                    <Redirect from="/" to="app/admindash" />
                }
                {
                  user.rol == 5 &&
                    <Redirect from="/" to="app/checkin-temp" />
                }
                {
                  user.rol == 6 &&
                    <Redirect from="/" to="app/transaction-cs" />
                }

               </Switch>
             </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth
    }
};

export default connect(mapStateToProps, actions)(DefaultLayout)
