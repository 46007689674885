import Moment from 'moment';

const initialState =
{
    dataTrans: [],
    saving: false,
    paginaTrans : 0,
    ticketsSold : 0,
    total : 0,
    transactions : 0,
};

export default ( state = initialState, action ) => {
    switch(action.type)
    {
        case 'LIST_TRANS':
             return {
                ...state,
                ticketsSold : action.payload.packages,
                total : action.payload.total,
                transactions : action.payload.trans,
            }
        case 'SAVE_DATA':
            return{
                ...state,
                dataTrans : action.payload,
            }

        default:
            return state;
    }
};