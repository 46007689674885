import Moment from 'moment';

const initialState =
{
    porce: [],
};

export default ( state = initialState, action ) => {
    switch(action.type)
    {
        case 'LIST':
             return {
                ...state,
                porce: action.payload,
            }

        default:
            return state;
    }
};