const initialState =
{
    shuttle_data: [],
    saving_shuttle : false,
};

export default ( state = initialState, action ) => {
    switch(action.type)
    {
        case 'LIST_SHUTTLE':
             return {
                ...state,
                shuttle_data: action.payload,
            }

        default:
            return state;
    }
};