const initialState =
{
    data: [],
    saving: false,
    usuarios:[],
    referencia:0,
};

export default (state = initialState, action) => {
    switch(action.type)
    {
        case 'LIST':
             return {
                ...state,
                data: action.payload
            }
        case 'SAVE_SUCCESS':
            return {
                ...state,
                saving: false,
                data: [...state.data, action.payload]
            }
        case 'SAVE':
            return {
                ...state,
                saving: true
            }
        case 'UPDATE_SUCCESS':
            let newp = action.payload;
            return {
                ...state,
                saving: false,
                data: state.data.map((d, i) => { return (d.id == newp.id) ? newp : d })
            }
        case 'REFERENCIA_TABLA':
            return{
                ...state,
                referencia : action.payload
            }
        case 'DELETE_SUCCES':
            let newp2 = action.payload
            let referencia = state.referencia
            console.log(referencia)
            console.log(state.data)
            return{
                ...state,
                data: state.data.filter(item => item.value !== newp2)
            }
        case 'INVENTARIO_APLICADO':
            return{
                ...state,
                data : [],
            }
        default:
            return state;
    }
};