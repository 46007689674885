const initialState =
{
    paginaUser : 0,
};

export default (state = initialState,action ) => {
    switch(action.type)
    {
        case 'PAGEUSER':
            return{
                ...state,
                paginaUser : action.payload,
            }

        default:
            return state;
    }
};