import Moment from 'moment';

const initialState =
{
    dataEvt: [],
    saving: false,
    pagina : 0,
};

export default ( state = initialState, action ) => {
    switch(action.type)
    {
        case 'LIST':
             return {
                ...state,
                dataEvt: action.payload,
                pagina : 0,
            }
        case 'SAVING':
            return{
                ...state,
                saving : true,
            }
        case 'NO_SAVING':
            return{
                ...state,
                saving : false,
            }
        case 'PAGE':
            return{
                ...state,
                pagina : action.payload,
            }
        case 'UPDATECHECKED':
            let newp = action.payload;
            return {
                ...state,
                saving: false,
                data: state.data.map((d, i) => { return (d.id == newp.id) ? newp : d })
            }

        default:
            return state;
    }
};