import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Cargando...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const ControlDeUsuarios = Loadable({
  loader: () => import('./views/Usuarios/Registro'),
  loading: Loading,
});

const RegistroUsuarios = Loadable({
  loader: () => import('./views/Usuarios/Registro/form'),
  loading: Loading,
});

const Form = Loadable({
  loader: () => import('./views/Dashboard/form.js'),
  loading: Loading,
});

const Logout = Loadable({
  loader: () => import('./views/Usuarios/Logout'),
  loading: Loading,
});

const Guest = Loadable({
  loader: () => import('./views/guests'),
  loading: Loading,
});

const Transactions = Loadable({
  loader: () => import('./views/transactions'),
  loading: Loading,
});

const Checkin = Loadable({
  loader: () => import('./views/Checkin'),
  loading: Loading,
});

const Tickets = Loadable({
  loader: () => import('./views/Tickets'),
  loading: Loading,
});

const Dash = Loadable({
  loader: () => import('./views/AdminDash'),
  loading: Loading,
});

const DashTemp = Loadable({
  loader: () => import('./views/AdminDashTemp'),
  loading: Loading,
});

const AdminCheck = Loadable({
  loader: () => import('./views/admincheck'),
  loading: Loading,
});

const AdminCheckTemp = Loadable({
  loader: () => import('./views/AdminCheckTemp'),
  loading: Loading,
});

const Config = Loadable({
  loader: () => import('./views/Config'),
  loading: Loading,
});

const Checkintemp = Loadable({
  loader: () => import('./views/CheckInTemp'),
  loading: Loading,
});

const MultipleEvents = Loadable({
  loader: () => import('./views/MultipleEvents'),
  loading: Loading,
});

const MultipleEventsForm = Loadable({
  loader: () => import('./views/MultipleEvents/regMultiple.js'),
  loading: Loading,
});

const TransactionsCS = Loadable({
  loader: () => import('./views/transactioncs'),
  loading: Loading,
});

const Shuttle = Loadable({
  loader: () => import('./views/Shuttle'),
  loading: Loading,
});

const ShuttleForm = Loadable({
  loader: () => import('./views/Shuttle/form.js'),
  loading: Loading,
});

const ShuttleCheck = Loadable({
  loader: () => import('./views/CheckInShuttle'),
  loading: Loading,
});

const Customer = Loadable({
  loader: () => import('./views/Customer'),
  loading: Loading,
});

const CustomerForm = Loadable({
  loader: () => import('./views/Customer/customerForm'),
  loading: Loading,
});


const Encuesta = Loadable({
  loader: () => import('./views/cuestionario'),
  loading: Loading,
});
const BuscarQR = Loadable({
  loader: () => import('./views/cuestionario/escaneo.js'),
  loading: Loading,
});

const AccessCodeRequests = Loadable({
  loader: () => import('./views/AccessCodes'),
  loading: Loading,
});

const HealthSubmition = Loadable({
  loader: () => import('./views/HealthProof'),
  loading: Loading,
});

const CheckHealthSubmition = Loadable({
  loader: () => import('./views/HealthProof/checkInfo.js'),
  loading: Loading,
});

const Onsite = Loadable({
  loader: () => import('./views/onsitetest'),
  loading: Loading,
});

const PaymentPlan = Loadable({
  loader: () => import('./views/PaymentPlan'),
  loading: Loading,
});

const Graphs = Loadable({
  loader: () => import('./views/Graphs'),
  loading: Loading,
});

const AutoReports = Loadable({
  loader: () => import('./views/Reports'),
  loading: Loading,
});
const PromoCodes = Loadable({
  loader: () => import('./views/PromoCodes'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
export const routes = [
  { path: '/app/', exact: true, name: 'Home', component: DefaultLayout },         // Login
  // { path: '/app/health_submition', exact: true, name: 'Health Submition', component: HealthSubmition },
  // { path: '/app/health_submition/check/:id', exact: true, name: 'Health Submition', component: CheckHealthSubmition },
  { path: '/app/dashboard', exact: true , name: 'Events', component: Dashboard },                 // Ventana principal
  { path: '/app/admindash', exact: true , name: 'Dash', component: Dash },
  { path: '/app/admindash-temp', exact: true , name: 'Dash temp', component: DashTemp },
  { path: '/app/dashboard/edit/:id',exact: true, name: 'Edit Registro', component:Form },
  { path: '/app/dashboard/agregar', exact: true , name: 'Add event', component:Form },
  { path: '/app/usuarios', exact: true,name: 'Usuarios', component: ControlDeUsuarios },          // lista de usuarios
  { path: '/app/usuarios/registro',exact: true, name: 'Registro' , component: RegistroUsuarios},
  { path: '/app/usuarios/edit/:id',exact: true, name: 'Editar' , component: RegistroUsuarios},
  { path: '/app/customer',exact: true, name: 'Customers' , component: Customer},
  { path: '/app/customer/edit/:id',exact: true, name: 'Customers' , component: CustomerForm},
  { path: '/app/autoreports', exact: true, name: 'Reports', component : AutoReports},
  { path: '/app/transactions' , exact: true, name : 'Transactions', component : Transactions},
  { path: '/app/checkin' , exact: true, name : 'Check in', component : Checkin},
  { path: '/app/checkin-temp' , exact: true, name : 'Check in temp', component : Checkintemp},
  { path: '/app/banditas', exact: true, name : 'Banditas', component : AdminCheck},
  { path: '/app/banditas-temp', exact: true, name : 'Banditas', component : AdminCheckTemp},
  { path: '/app/config', exact : true, name : 'config', component : Config},
  { path: '/app/ticket' , exact: true, name : 'Ticket', component : Tickets},
  { path: '/app/MultipleEvents', exact: true, name : 'MultipleEvents', component : MultipleEvents},
  { path: '/app/MultipleEvents/add', exact: true, name : 'Add MultipleEvents', component : MultipleEventsForm},
  { path: '/app/MultipleEvents/edit/:id', exact: true, name : 'Edit MultipleEvents', component : MultipleEventsForm},
  { path: '/app/shuttle', exact: true, name : 'Shouttle', component : Shuttle},
  // { path: '/app/graphs', exact: true, name : 'Graphs', component : Graphs},
  { path: '/app/shuttle/add', exact: true, name : 'ADD shuttle', component : ShuttleForm},
  { path: '/app/shuttle-check', exact: true, name : 'check shuttle', component : ShuttleCheck},
  { path: '/app/access_code_requests', exact: true, name : 'access code requests', component : AccessCodeRequests},
  { path: '/app/guests', exact: true, name : 'Guest Lists', component : Guest},
  // { path: '/app/onsitetest', exact: true, name : 'on site tests', component : Onsite},
  { path: '/app/payment_plans', exact: true, name : 'Payment plans', component : PaymentPlan},
  { path: '/app/promo_codes', exact: true, name : 'Promo Codes', component : PromoCodes},
  { path: '/app/logout', name: 'Logout', component: Logout },                       // Logout

  // /*Encuesta*/
  { path: '/app/encuesta', exact: true , name: 'Encuesta', component: Encuesta },
  { path: '/app/searchqr/:opcion', exact: true , name: 'Search QR', component: BuscarQR },
];

export const routesCheckIn = [
  { path: '/app/checkin' , exact: true, name : 'Check in', component : Checkin},
  { path: '/app/guests', exact: true, name : 'Guest Lists', component : Guest},
  { path: '/app/logout', name: 'Logout', component: Logout },                       // Logout
];

export const routesOnSite = [
  { path: '/app/checkin' , exact: true, name : 'Check in', component : Checkin},
  { path: '/app/guests', exact: true, name : 'Guest Lists', component : Guest},
  // { path: '/app/onsitetest', exact: true, name : 'on site tests', component : Onsite},
  { path: '/app/logout', name: 'Logout', component: Logout },                       // Logout
];

export const routesShuttles = [
  { path: '/app/checkin' , exact: true, name : 'Check in', component : Checkin},
  { path: '/app/shuttle-check', exact: true, name : 'check shuttle', component : ShuttleCheck},
  { path: '/app/logout', name: 'Logout', component: Logout },                       // Logout
];

export const routesCheckInTemp = [
  { path: '/app/checkin-temp' , exact: true, name : 'Check in', component : Checkintemp},
  { path: '/app/logout', name: 'Logout', component: Logout },                       // Logout
];

export const routesCustomer = [
  { path: '/app/transactions-cs' , exact: true, name : 'Transactions', component : TransactionsCS},
  { path: '/app/guests', exact: true, name : 'Guest Lists', component : Guest},
  { path: '/app/customer',exact: true, name: 'Customers' , component: Customer},
  { path: '/app/customer/edit/:id',exact: true, name: 'Customers' , component: CustomerForm},
  // { path: '/app/health_submition', exact: true, name: 'Health Submition', component: HealthSubmition },
  // { path: '/app/health_submition/check', exact: true, name: 'Health Submition', component: CheckHealthSubmition },
  { path: '/app/logout', name: 'Logout', component: Logout },                       // Logout
];

export const routesAdminCheck = [
  { path: '/app/admindash', exact: true , name: 'Dash', component: Dash },
  { path: '/app/admindash-temp', exact: true , name: 'Dash temp', component: DashTemp },
  // { path: '/app/health_submition', exact: true, name: 'Health Submition', component: HealthSubmition },
  // { path: '/app/health_submition/check', exact: true, name: 'Health Submition', component: CheckHealthSubmition },
  { path: '/app/ticket' , exact: true, name : 'Ticket', component : Tickets},
  { path: '/app/banditas', exact: true, name : 'Banditas', component : AdminCheck},
  { path: '/app/banditas-temp', exact: true, name : 'Banditas', component : AdminCheckTemp},
  { path: '/app/transactions' , exact: true, name : 'Transactions', component : Transactions},
  { path: '/app/customer',exact: true, name: 'Customers' , component: Customer},
  { path: '/app/customer/edit/:id',exact: true, name: 'Customers' , component: CustomerForm},
  { path: '/app/checkin' , exact: true, name : 'Check in', component : Checkin},
  { path: '/app/checkin-temp' , exact: true, name : 'Check in temp', component : Checkintemp},
  { path: '/app/logout', name: 'Logout', component: Logout }, 
];

