const initialState =
{
    currentState : {
        pageSize : 10,
        page : 1,
        sorted : [],
        filtered : [],
    },
    page: 1,
    event_id : '',
    filter : '',
};

export default ( state = initialState, action ) => {
    switch(action.type)
    {
        case 'UPDATE_EVT':
            return{
                ...state,
                event_id : action.payload,
            }
        case 'UPDATE_FILTER':
            return{
                ...state,
                filter : action.payload,
            }
        case 'UPDATE_CURRENT':
            return{
                ...state,
                page : action.payload,
            }
            
        default:
            return state;
    }
};